import React from "react"

import { storage } from '../settings'

function SignPreviewImage(props) {
    const url = props.root ? storage.url + props.src : storage.images + props.src;

    return (
      <img 
        src={url} 
        alt={props.alt} 
        style={Object.assign({maxWidth: "100%"}, props.style)} 
        loading="lazy"
      />
    );
}

export default SignPreviewImage