import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import { isMobile } from "react-device-detect";

import BlueButton from "../buttons/BlueButton"
import HoverButton from "../buttons/HoverButton"
import TextField from "../fields/TextField"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const SearchForm = styled.div`
  min-width: 300px;

  > div {
    display: flex;

    input {
      flex: 1;
    }
    a {
      flex: 0;
    }
  }

  > p {
    font-size: 80%;
    color: #999;
  }
`

const TemplateSearchForm = (props) => {
  const [keyword, setKeyword] = useState(props.search);

  useEffect(
    () => {
        setKeyword(props.search)
    }, 
    [props.search]
  );

  function onChange(e) {
    setKeyword(e.target.value);
  }
  function onSearch() {
    props.onSearch(keyword);
  }

  return (
    <SearchForm>
        <div>
          <TextField 
              name="search"
              value={keyword} 
              wide   
              label="Search"         
              onChange={onChange} 
              onEnter={onSearch}
          >
            <BlueButton
              icon={faSearch}
              disabled={!keyword || keyword.length === 0}
              onClick={onSearch} 
            />
          </TextField>
        </div>
        <p>Examples: Hair Salon, Tattoo, Auto Sales</p>
    </SearchForm>
  )
}

export default TemplateSearchForm
