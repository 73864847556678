import React, { useState, PureComponent } from "react"
import styled from "styled-components"

import { devices } from "../settings"

const Grid = styled.div`
    margin-top: 25px;
    display: flex;
    flex-flow: wrap;
    box-sizing: border-box;

    > * {
        background-color: white;
        border: 1px solid #eee;
        padding: 10px !important;
        border-radius: 5px;
        text-align: center;
        position: relative;
        max-width: 390px;
        min-width: 150px;
        height: 180px;
        margin: 5px;
        display: inline-block;
        cursor: pointer;

        @media ${devices.mobile} {
            display: block;
            max-width: unset;
            width: 100%;
        }

        justify-content: center;

        > h3 {
            font-size: 12px;
            text-align: center;
            margin: 5px;
        }

        > button {
            position: absolute;
            display: none;
            right: 5px;
            bottom: 5px;
        }

        &:hover {
            > button {
                display: block;
            }
        }
    }

    > div.processing {
        > button {
            display: block;
        }
    }
`

const SignGrid = (props) => {

  return (
    <Grid>
        { props.children }
    </Grid>
  )
}

export default SignGrid