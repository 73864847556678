import React from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

import { colors } from "../../settings"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const Button = styled.button`
    background-color: ${colors.orange};
    color: white;
    border: 0px;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;

    &[disabled] {
      opacity: .8;
      cursor: not-allowed;
    }
    svg {
      margin-right: ${props => props.label ? 5:0}px;
    }
`

function OrangeButton(props) {
    return (
      <Button label={props.label} style={props.style}>
        { (props.icon || props.busy) && <FontAwesomeSvgIcon icon={props.busy ? faSpinner : props.icon} spin={props.busy} />}
        {props.label}
      </Button>
    );
}

export default OrangeButton