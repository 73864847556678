/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

import { devices } from "../../settings"

const Layout = styled.div`
  display: flex;
  width: 100%;
  align-items: ${props => props.align || "center"};
  flex-flow: ${props => props.reverse ? "row-reverse":"initial"};

  > div {
      &:first-child {
        flex-basis: ${props => props.left || 50}%;
        padding-right: ${props => props.padding == 0 || isMobile ? 0 : props.padding || 5}px;
      }
      &:last-child {
        flex-basis: ${props => props.right || 50}%;
        padding-left: ${props => props.padding == 0 || isMobile ? 0 : props.padding || 5}px;
      }
  }

  @media ${devices.mobile} {
      display: block;
  }
`

export default (props) => {
  return (
    <Layout align={props.align} padding={props.padding} left={props.left} right={props.right} reverse={props.reverse} className={props.className}>
        {props.children}
    </Layout>
  )
}