import React, { useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import SignPreviewImage from "./SignPreviewImage"
import Button from "./buttons/OrangeButton"

import { postData } from "../services/server"

const Preview = styled.div`

`

const SignTemplatePreview = (props) => {
  const [processing, setProcessing] = useState(false);

  function onCustomize() {
    const sign = props.sign;

    setProcessing(true)

    /*
    postData("/api/v2/sign/clone", { signId: sign.id, l: "Site V2"},
        function(response) {
            if (response.message && response.message.length)
                alert(response.message);
            else 
                navigate("/build?id=" + response.sign.id);
        },
        function(error) {
            alert("An error occured creating the sign.");
            setProcessing(false);
        },
    );
    */
   navigate("/shop/?uid=" + sign.uid);
}

  return (
    <Preview className={processing ? "processing":""} onClick={() => onCustomize()}>
        <h3>{props.sign.title}</h3>
        <Button 
            label="Customize It"
            busy={processing}
            style={{ opacity: ".9" }}
        />
        <SignPreviewImage 
            root 
            src={props.sign.preview_url} 
            alt={props.sign.keywords}
            responsive 
            style={{ 
                maxHeight: "125px", 
                maxWidth: "100%" 
            }} 
        />
    </Preview>
  )
}

export default SignTemplatePreview