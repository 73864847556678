import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import queryString from 'query-string'
import Fuse from "fuse.js";

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import TemplateSearchForm from "../components/forms/TemplateSearchForm"
import SignGrid from "../components/SignGrid"
import SignTemplatePreview from "../components/SignTemplatePreview"
import Layout2Columns from "../components/layout/Layout2Columns";

const TemplatesSearchPage = () => {
  const [keyword, setKeyword] = useState("");
  const [signs, setSigns] = useState(null);

  const data = useStaticQuery(graphql`
    {
        signs: allMysqlLandingPageSigns {
            edges {
                sign: node {
                    id: sign_id
                    uid: sign_uid
                    title
                    keywords
                    preview_url
                }
            }
        }
    }
  `);

useEffect(
    () => {
        const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

        setKeyword(params.search || "");
    }, 
    []
  );

useEffect(
    () => {
      var options = {
        shouldSort: true,
        includeScore: true,
        includeMatches: true,
        tokenize: false,
        findAllMatches: false,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 3,
        keys: [
          "sign.title",
          "sign.keywords",
        ]
      };

      if (keyword != null && keyword.length > 0) {
        const fuse = new Fuse(data.signs.edges, options); // "list" is the item array
        const results = fuse.search(keyword);

        if (results.length > 25)
            setSigns(results.slice(0,25));
        else
            setSigns(results);         
      }
      else {
        setSigns([])
      }
    }, 
    [keyword]
  );

  return (
    <>
      <SEO page="templates" title="Search Sign Templates" />
      <Page breadcrumb={[
                { url: "/", title: "Home"},
                { url: "/templates", title: "Sign Templates"},
                { url: null, title: "Search"}
            ]}>

        <Layout2Columns left={65} right={35}>
          <div>
            <PageHeader title="Sign Templates" subtitle="Choose a template and customize it in our Sign Builder." />
          </div>
          <div>
              <TemplateSearchForm 
                search={keyword}
                onSearch={(search) => setKeyword(search)}
              />
          </div>
        </Layout2Columns>

        { signs && signs.length > 0 ? 
            <SignGrid>
                {signs.map(({item}, index) => (
                    <SignTemplatePreview
                        key={index}
                        sign={item.sign}
                    />
                ))}
            </SignGrid>
            :
            <p>No signs found.</p>
        }
      </Page>
    </>
  )
}

export default TemplatesSearchPage